.DayPicker table tbody td:not(.CalendarDay) {
  border: none;
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top;
}

.CalendarDay__default {
  color: #203c63;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #2bb7d9;
  border: 1px double #ebedf0;
  color: #fff;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #4bdbff;
}
.CalendarDay__blocked_out_of_range {
  color: #a8b6ca;
}

.CalendarDay__selected_span {
  background: #2bb7d9;
  border: 1px double #ebedf0;
  color: #fff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #2bb7d9;
  border: 1px double #ebedf0;
  color: #fff;
}

.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  top: 0;
}

.DayPickerNavigation_button__default {
  border: 0;
}

.DateInput {
  width: 180px;
  background: transparent;

  &:after {
    content: "";
    position: absolute;
    right: 13px;
    top: 10px;
    width: 16px;
    height: 16px;
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMkJCN0Q5O30KPC9zdHlsZT4KPHRpdGxlPkdyb3VwQDF4PC90aXRsZT4KPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTEzLDJWMGgtMnYySDVWMEgzdjJIMHYxNGgxNlYySDEzeiBNMTUsMTVIMVY2aDE0VjE1eiBNMTUsNUgxVjNoMTRWNXoiLz4KCTxyZWN0IHg9IjExIiB5PSIxMSIgY2xhc3M9InN0MCIgd2lkdGg9IjIiIGhlaWdodD0iMiIvPgoJPHJlY3QgeD0iMTEiIHk9IjgiIGNsYXNzPSJzdDAiIHdpZHRoPSIyIiBoZWlnaHQ9IjIiLz4KCTxyZWN0IHg9IjciIHk9IjgiIGNsYXNzPSJzdDAiIHdpZHRoPSIyIiBoZWlnaHQ9IjIiLz4KCTxyZWN0IHg9IjciIHk9IjExIiBjbGFzcz0ic3QwIiB3aWR0aD0iMiIgaGVpZ2h0PSIyIi8+Cgk8cmVjdCB4PSIzIiB5PSI4IiBjbGFzcz0ic3QwIiB3aWR0aD0iMiIgaGVpZ2h0PSIyIi8+Cgk8cmVjdCB4PSIzIiB5PSIxMSIgY2xhc3M9InN0MCIgd2lkdGg9IjIiIGhlaWdodD0iMiIvPgo8L2c+Cjwvc3ZnPgo=")
      center / contain no-repeat;
  }
}

.DateInput_input {
  font-weight: 400;
  font-size: 14px;
  padding: 9px;
  color: #2c2c2c;
  background-color: #fff;
  width: 100%;
  border: 1px solid #ebedf0;
  border-radius: 4px;
}

.DateInput_input__focused {
  border-bottom: 1px solid #2bb7d9;
}

.DateRangePickerInput_arrow {
  display: none;
}

.DayPickerNavigation_button__default {
  &:focus,
  &:hover {
    border: 0;
  }
}

.CalendarMonth_caption {
  color: #203c63;
  font-size: 16px;
}
.DayPicker_weekHeader {
  text-transform: uppercase;
  color: #a8b6ca;
  top: 54px;
}

.DateInput_fang {
  display: none;
}

.DayPicker__withBorder {
  box-shadow: none;
}

.DateInput_1:first-child {
  margin-right: 20px;
}

@media (max-width: 768px) {
  .DateRangePicker_picker {
    top: 100px !important;
  }
  .DateRangePicker {
    padding: 24px 25px 0;
    width: 100%;
    text-align: center;
    height: 110px;
  }
  .DateInput_1:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .DateInput {
    width: 100%;
  }

  .DateInput_input {
    line-height: 19px;
  }

  .compare-wrapper {
    width: 345px;
    height: 502px;

    .label-calendar-input {
      left: 21px;
      font-size: 17px;
      top: 20px;
    }
    &.global {
      width: 330px;
      height: 498px;
    }

    .label-date-input-start,
    .label-date-input-end {
      display: none;
    }
    .button-presets {
      padding: 5px 13px;
      .range {
        margin-right: 10px;
      }
      .btn-daterange-preset {
        padding: 7px 9px;
        font-size: 11px;
      }
    }
  }
}
