@import "../../utils/theme";

$size: 56px;
$borderWidth: 4px;
$duration: 1.4s;

.spinner {
  display: inline-block;
  position: relative;
  width: $size;
  height: $size;

  div {
    position: absolute;
    border: $borderWidth solid $color-primary;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple $duration cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -$duration/2;
    }
  }

  &-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 100;

    &.on-backdrop {
      background: rgba($color-white, 0.4);
      width: 100%;
      height: 100%;
    }

    &.is-relative {
      position: relative;
      transform: translateY(0%) translateX(-50%);
      width: 100%;
    }
  }

  &-backdrop {
    position: relative;

    .spinner {
      display: inline;
      position: sticky;
      top: calc(50% - #{$size/2});
      left: calc(50% - #{$size/2});
      z-index: 101;
    }
  }

  // source https://loading.io/css/
  @keyframes lds-ripple {
    0% {
      top: ($size/2) - $borderWidth;
      left: ($size/2) - $borderWidth;
      width: 2 * $borderWidth;
      height: 2 * $borderWidth;
      opacity: 1;
    }

    100% {
      top: 0;
      left: 0;
      width: $size;
      height: $size;
      opacity: 0;
    }
  }
}

.scroll{
  overflow-x: scroll;
}
