@import "../../../utils/theme";

.admin-flag-container {
  height: fit-content;
  display: flex;
  flex-direction: row;

  &__flag {
    width: 100%;
    border: 1px solid #ebedf0;
    border-radius: 4px;
    background-color: $color-dark-blue-10;
    margin-bottom: 15px;
    padding: 10px;

    &__caption-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__caption {
        margin-left: 8px;
      }
    }

    &__description-container {
      padding-top: 8px;

      &__description {
        word-break: break-all;
      }
    }

    &__resolve-button {
      width: fit-content;
      margin-top: 8px;
      margin-left: auto;
      margin-right: 0px;
      border: 1px solid $color-deep-orange-100;
      border-radius: 12px;
      padding: 3px 8px;
      cursor: pointer;
      color: $color-deep-orange-100;

      &:hover {
        background-color: $color-deep-orange-100;
        color: #fff;
      }
    }
  }
}
