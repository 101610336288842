.graph-bar {
  table-layout: auto;

  tr {
    display: table;
    width: 100%;
  }

  thead {
    display: block;

    th {
      color: #a8b6ca;
      font-size: 10px;
      padding-bottom: 8px;
      vertical-align: bottom;
      letter-spacing: 0.75px;
    }
  }

  tbody {
    display: block;
    padding-top: 17px;
    max-height: 200px;
    margin-right: -5px;
    overflow-y: auto;

    &.graph-bar-full {
      height: unset;
    }

    &.oneFacility {
      height: 70px;
    }

    tr {
      td:last-child {
        padding-right: 5px;
        .graph-bar-text {
          text-align: right;
          padding-right: 10px;
        }
      }
    }
  }

  td {
    font-size: 11px;
    line-height: 18px;
    min-height: 30px;
    border: none;
    padding: 8px 8px 8px 0;
  }

  thead {
    :first-child {
      width: 1%;
    }

    :last-child {
      width: 100%;
      text-align: right;
    }
  }
}

.graph-bar-second {
  filter: grayscale(0.3) opacity(0.5);
}

.graph-bar-bar {
  width: 200px;

  padding-top: 10px;
  padding-bottom: 10px;
}

.graph-bar-custom-axis {
  tbody {
    td {
      border: 0;
      svg {
        overflow: visible;
      }
    }
  }

  .full-width {
    font-size: 12px;
  }
}

.graph-bar-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
  color: #203c63;
  font-weight: 500;
  display: table;
  table-layout: fixed;
  width: 97%;

  span {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.graph-bar-floating-wrapper {
  position: relative;
  top: 0;
}

.graph-bar-floating-label {
  text-align: center;
  border-radius: 6px;
  padding: 2px 8px;
  border: 1px solid #e7ebef;
  background-color: #fafcff;
  //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

  span {
    color: #3d3e41;
    font-size: 10px;
    line-height: 8px;
    font-weight: 500;
  }

  i {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 12px;
    margin-right: 2px;
    &.tick-1 {
      margin-left: 11px;
    }
  }

  /* Position the tooltip */
  position: absolute;
  left: 50%;
  bottom: 18px;
  margin-left: -50px;
  z-index: 1;

  // arrow
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(250, 252, 255, 0);
    border-top-color: #fafcff;
    border-width: 4px;
    margin-left: -4px;
  }
  &:before {
    border-color: rgba(231, 235, 239, 0);
    border-top-color: #e7ebef;
    border-width: 5px;
    margin-left: -5px;
  }
}
