$color-primary: #2bb7d9;
$color-golden-100: #f9d947;

.star-rating__item {
  display: inline-block;
  cursor: pointer;
  font-size: 24px;
  padding: 5px;
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 24px;

  input {
    visibility: hidden;
    display: none;
  }

  &:hover {
    background-color: rgba($color-primary, 0.05);
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    content: "\2606";
  }

  &--active {
    color: $color-golden-100;

    &::before {
      content: "\2605";
    }
  }
}
