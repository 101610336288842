* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  color: #203c63;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  background-color: #fafcff;
}

strong {
  font-weight: bold;
  white-space: pre-wrap;
}

a {
  color: #2bb7d9;
  text-decoration: underline;
  cursor: pointer;
}

a:hover {
  opacity: 0.7;
}

em {
  font-style: italic;
}
