$color-primary: #2bb7d9;
$color-deep-orange-100: #ff3a45;
$color-dark-blue-100: #203c63;
$color-dark-blue-70: lighten($color-dark-blue-100, 30%);
$color-dark-blue-40: lighten($color-dark-blue-100, 60%);
$color-dark-blue-10: #e8ebef;
$color-slate-blue-100: #8482f9;
$color-golden-100: #f9d947;
$color-green-100: #1bd760;
$color-mint-100: #6cd7c3;
$color-mint-70: lighten($color-mint-100, 30%);
$color-gray-100: #a8b6ca;
$color-gray: #bdc4cc;
$color-white: #ffffff;
