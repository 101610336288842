@import "../../utils/theme";

.rc-pagination {
  &-item {
    color: $color-dark-blue-100;
    border-color: $color-gray-100;

    &-active {
      background: $color-primary;
      border-color: $color-primary;
    }

    &:hover {
      color: $color-primary;
      border-color: $color-primary;
    }
  }

  &-next,
  &-prev {
    a {
      color: $color-dark-blue-100;
      text-decoration: none;
    }
  }

  &-jump-next,
  &-jump-prev {
    &:after {
      color: darken($color-dark-blue-10, 20%);
    }
  }
}
