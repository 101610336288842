.pie-chart {
  flex: 9;
}

.additional-data {
  padding: 0 10px;
  border: 1px solid #ebedf0;
  max-height: 150px;
  overflow: auto;
  li {
    padding: 5px 0;
  }
  &__container {
    flex: 3;
  }
  &__header {
    padding-bottom: 5px;
    font-weight: 800;
  }
}

.pie-legend {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px 0;
  &__bullet {
    width: 8px;
    display: inline-flex;
    background: red;
    height: 8px;
    margin-right: 5px;
    border-radius: 10px;
  }
  &__item {
    padding: 8px;
  }
}
