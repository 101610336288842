.aggregation-row {
  flex-flow: row wrap;
  margin-bottom: 12px;
  margin-top: 20px;
  place-content: stretch flex-start;
  align-items: stretch;
  align-self: stretch;
  display: flex;

  & > div {
    color: #a8b6ca;
  }

  & > div:nth-child(1) {
    color: #203c63;
  }
}

.button-load-more {
  margin: 20px 0 0;
  display: block;
}

$lg: 1170px;
$md: 990px;
$sm: 768px;
$xs: 460px;

@media (max-width: $sm) {
  .reporting-wrapper {
    flex-flow: column;
    place-content: unset;
    align-items: unset;
    align-self: unset;
    display: flex;
  }
}

@media (max-width: $xs) {
  .aggregation-row {
    margin-bottom: 5px;
    margin-top: 10px;
  }
}
