.dropdown-0-25 {
  width: 100%;
  height: 100%;
  text-align: left;
}
.dropdown-0-26 {
  display: flex;
  padding: 15px;
  padding-bottom: 12px;

  .dot-wave {
    display: flex;
    align-items: baseline;
    color: #a8b6ca;
    font-weight: 900;

    > span {
      $duration: 500ms;
      animation-name: dot-wave;
      animation-duration: $duration;
      animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
      animation-fill-mode: both;
      animation-iteration-count: infinite;

      @for $i from 2 through 3 {
        &:nth-child(#{$i}) {
          animation-delay: $duration / 3 * ($i - 1);
        }
      }
    }
  }
}

.dropdown-0-27 {
  width: 100%;
  height: 100%;
  text-align: left;
}
.dropdown-0-28 {
  padding: 15px;
  padding-bottom: 5px;
}
.dropdown-0-29 {
  margin-right: 15px;
}
.dropdown-0-30 {
  line-height: 16px;
  padding-bottom: 5px;
}
.dropdown-0-31 {
  color: #a8b6ca;
  line-height: 16px;
}
.dropdown-0-32 {
  border-bottom: 1px solid #ebedf0;
}
.dropdown-0-33 {
  margin: 10px;
}
.dropdown-0-34 {
  margin: 10px;
}
.dropdown-0-35 {
  margin: 10px;
}
.dropdown-0-36 {
  margin-right: 10px;
}
.dropdown-0-37 {
  margin: 10px;
}
.dropdown-0-38 {
  margin-right: 5px;
}
.dropdown-0-39 {
  margin: 10px;
}
.dropdown-0-40 {
  margin: 10px;
}
.dropdown-0-41 {
  margin-bottom: 5px;
}

@keyframes dot-wave {
  0%,
  100% {
    transform: translateY(2px);
  }

  50% {
    transform: translateY(3px);
  }
}
