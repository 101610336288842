.noteslist-0-52 {
  margin-bottom: 15px;
}
.noteslist-0-53 {
  padding: 20px;
}
.noteslist-0-54 {
  width: 100%;
  margin-top: 10px;
}
