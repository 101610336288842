.icon {
  &.icon-compare {
    margin-right: 0.4em;
  }

  &.icon-export {
    margin-right: 1em;
  }
}

.chart-header {
  display: flex;
  justify-content: space-between;
}

.reporting-block-row {
  .half:nth-child(odd) {
    .compare-wrapper {
      left: 0;
      right: auto;
    }
  }

  .half:nth-child(even) {
    .compare-wrapper {
      left: auto;
      right: 0;
    }
  }
}

$lg: 1170px;
$md: 990px;
$sm: 768px;
$xs: 460px;

@media (max-width: $sm) {
  .chart-header {
    flex-wrap: wrap-reverse;
  }

  .reporting-block {
    &__header {
      display: flex;
      padding: 9px 15px;

      a {
        margin-left: 12px;
        font-size: 10px;
      }
      button span {
        font-size: 10px;
      }
    }

    &__btn-see-full-report {
      margin: 0 0 15px 13px;
      bottom: 0px;
      font-size: 11px;
      &.back {
        position: relative;
        margin: 0px 0 20px 0px;
      }
    }

    &__body {
      padding: 15px 15px 25px 15px;
    }
  }

  .reporting-block__btn-see-full-report {
    margin: 0 0 20px 15px;
  }
}

@media (max-width: $xs) {
  .chart-header {
    flex-direction: column;
    align-items: flex-end;
    .labels {
      text-align: left;
    }
  }

  .reporting-block {
    &__body {
      padding: 12px 9px 30px;
    }

    &__btn-see-full-report {
      margin: 0 0 15px 10px;
      bottom: 0px;
      font-size: 11px;
      &.back {
        position: relative;
        margin: 0px 0 20px 0px;
      }
    }

    &__btn-see-full-report {
      margin: 0 0 12px 10px;
      bottom: 0px;
      font-size: 11px;
      &.back {
        position: relative;
        margin: 0px 0 20px 0px;
      }
    }
  }
}
