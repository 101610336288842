.slides-0-37 {
  flex: 1;
}
.slides-0-38 {
  margin-bottom: 15px;
}
.slides-0-39 {
  display: flex;
  flex-direction: row;
}
.slides-0-40 {
  width: 280px;
  padding-left: 15px;
}
