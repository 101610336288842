.flagform-0-25 {
  display: flex;
  flex-direction: row;
  margin: 20px;
}
.flagform-0-26 {
  margin-left: 15px;
}
.flagform-0-27 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flagform-0-28 {
  margin-left: 8px;
}
.flagform-0-29 {
  padding-top: 8px;
}
