.compare-wrapper {
  border-radius: 4px;
  background: #fff;
  position: absolute;
  width: 620px;
  height: 480px;
  padding-top: 16px;
  right: 0;
  box-shadow: 0 1px 15px 0 rgba(51, 51, 51, 0.1);
  z-index: 999;

  &.global {
    width: 620px;

    .label-calendar-input {
      left: 21px;
      font-size: 18px;
    }

    .button-presets {
      margin-top: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn-daterange-apply {
        margin-top: 0px;
        order: 1;
        margin-left: 20px;
        padding: 8px 20px;
      }
      .btn-daterange-clear {
        position: relative;
        margin-top: 0px;
      }
    }
  }

  .DateRangePicker {
    width: 100%;
    text-align: right;
    padding: 0 25px;
    margin-top: 31px;
  }
}

.button-presets {
  padding: 5px 24px;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  position: absolute;
  bottom: 23px;
  width: 100%;
  z-index: 10;

  .range {
    display: flex;
    align-items: center;
  }

  .btn-daterange-preset {
    text-decoration: none;
    color: #5f5f5f;
    border-radius: 0;
    border-right: 1px solid #ccc;
    box-sizing: border-box;
    cursor: pointer;
    padding: 7px 12px;
    font-size: 13px;
    display: block;
    margin-bottom: 0px;
    text-align: left;

    &:nth-child(1) {
      padding-left: 0;
    }

    &:nth-child(3) {
      border-right: 0;
    }

    &.selected {
      color: #2bb7d9;
      //border-color: #2bb7d9;
    }
  }
}

.label-calendar-input {
  position: absolute;
  color: #203c63;
  top: 30px;
  font-weight: 600;
  left: 30px;
  font-size: 21px;
}

.label-date-input-start {
  position: absolute;
  color: #656565;
  top: 26px;
  right: 341px;
}

.label-date-input-end {
  position: absolute;
  color: #656565;
  top: 26px;
  right: 157px;
}

$md: 990px;
$sm: 768px;
$mds: 600px;

@media (max-width: $md) {
  .compare-wrapper {
    left: auto;
    right: 0;
    margin-left: 0;
  }
}

@media (max-width: $sm) {
  .compare-wrapper {
    width: 345px;
    height: 502px;

    .label-calendar-input {
      left: 21px;
      font-size: 17px;
      top: 20px;
    }
    &.global {
      width: 330px;
      height: 498px;
    }

    .label-date-input-start,
    .label-date-input-end {
      display: none;
    }
    .button-presets {
      padding: 5px 13px;
      .range {
        margin-right: 10px;
      }
      .btn-daterange-preset {
        padding: 7px 9px;
        font-size: 11px;
      }
    }
  }
}
