.ReactModal {
  &__Body {
    &--open {
      overflow: hidden;
    }
  }

  &__Overlay {
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4) !important;
    transition: opacity 250ms;
    opacity: 0;
    z-index: 1005;
    padding: 40px;

    @media (max-width: 780px) {
      padding: 20px;
    }

    &.large {
      @media (max-width: 940px) {
        padding: 20px;
      }
    }

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
      pointer-events: none;
    }

    &.is-fullscreen {
      padding: 0;
    }
  }

  &__Content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-height: 100%;
    width: 100%;
    max-width: 760px;
    border: 0;
    overflow: hidden;
    outline: none;
    background: #fff;
    box-shadow: 0 0.5px 10px 0 rgba(0, 0, 0, 0.13);
    transition: transform 350ms;

    padding: 0 16px;

    &.small {
      max-width: 560px;
    }

    &.large {
      max-width: 920px;
    }

    &.is-window {
      transform: translateY(50px);

      &.ReactModal__Content {
        &--after-open {
          transform: translateY(0);
        }

        &--before-close {
          transform: translateY(-50px);
        }
      }
    }

    &.is-fullscreen {
      transform: translateY(50px) scale(0.95);
      margin: 0;
      width: 100%;
      height: 100%;
      max-width: none;

      &.ReactModal__Content {
        &--after-open {
          transform: translateY(0) scale(1);
        }

        &--before-close {
          transform: translateY(-50px) scale(1.05);
        }
      }
    }

    .list-table-basic {
      thead {
        top: 0;
        display: block;
        position: sticky;
        background: white;
      }
      tbody {
        display: block;
        overflow-y: auto;
      }
    }
  }

  &__InnerContent {
    overflow: auto;
  }

  &__Footer {
    margin: 16px 0;
  }
}
