.list-table-reporting-wrapper {
  max-height: 200px;
  &.list-table-reporting-full {
    max-height: unset;
  }
  margin-top: 25px;
}
.list-table-reporting tbody,
.list-table-reporting thead {
  display: block;
}

.list-table-reporting {
  thead {
    th {
      letter-spacing: 0.2px;
    }
  }
  tr {
    display: inline-flex;
    width: 100%;
    td {
      border-bottom: 0;
      &.table__no-rows-renderer {
        border-bottom: thin solid rgb(235, 237, 240);
      }
    }
  }

  .ellipsis {
    display: table;
    table-layout: fixed;
    width: 92%;
    span {
      display: table-cell;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .textRight {
    text-align: right;
    padding-right: 15px;
  }
}

.listtable-reporting-0-52 {
  padding-left: 3px;
}

.list-table-reporting tbody {
  overflow: auto;
  &.limit-height {
    height: 135px;
  }

  &.empty {
    height: 30px;
  }

  .circle {
    height: 9px;
    width: 9px;
    margin: 3px;
    display: inline-block;
    border-radius: 50%;
    transform: translateY(-50%);
    background-color: #2bb7d9;
  }
}

.list-table-reporting {
  tr {
    th:first-child,
    td:first-child {
      padding-left: 0;
    }
  }
  thead {
    tr {
      th {
        color: #a8b6ca;
        font-size: 10px;
        padding: 5px 8px 5px 0px;
        background: transparent;
        height: auto;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 10px;
        padding: 7px 8px 7px 0px;
      }
    }
    tr:nth-child(even) {
      td {
        background-color: #fafcff;
      }
    }
  }
}

$xs: 460px;

@media (max-width: $xs) {
  .list-table-reporting {
    thead {
      tr {
        th {
          padding: 6px 4px 6px 0px;
          font-size: 8px;
          line-height: 10px;
          .icon {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 10px;
          padding: 6px 4px 6px 0px;
        }
      }
    }
  }
}
