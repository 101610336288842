@import "../../utils/theme";

.rc-tree {
  width: 100%;

  & > li {
    padding: 8px 0;

    & + li {
      border-top: 1px solid $color-dark-blue-10;
    }
  }

  &-title {
    font-weight: 400;
  }

  &-child-tree {
    padding-left: 24px;

    li:not(.rc-tree-treenode-switcher-close) {
      display: flex;
      align-items: center;
      padding-right: 24px;
    }
  }

  &-node-content-wrapper {
    display: flex;
    flex: 1 1 0;
    padding: 12px 0;
  }

  &-treenode-switcher {
    &-close {
      display: none;

      .rc-tree-title {
        opacity: 0.6;
      }
    }

    &-open {
      > .rc-tree-node-content-wrapper > .rc-tree-title {
        font-weight: 600;
      }
    }
  }

  &-checkbox {
    height: 1.5em;
    width: 1.5em;
    min-width: 1.5em;
    min-height: 1.5em;
    border-radius: 0.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $color-primary;
    background-color: transparent;
    float: left;
    margin: 8px;

    i {
      display: none;
    }

    &-checked {
      background-color: $color-primary;

      &:after {
        content: "";
      }

      i {
        display: inline-block;
      }
    }

    &-disabled {
      border-color: $color-gray-100;
      background-color: transparent;

      &.checkbox-icon--checked {
        background-color: $color-gray-100;
      }
    }

    &-indeterminate {
      background: $color-primary;
      border-color: $color-primary;

      &:after {
        content: "-";
        color: $color-white;
        font-weight: bold;
      }
    }
  }
}
