table {
  width: 100%;
}

/**
 * Header
 */
table th {
  width: 1255px;
  height: 45px;
  color: #a8b6ca;
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: left;
}

table tbody tr {
  background: white;
}

table tbody td {
  vertical-align: middle;
  border-top: thin solid rgb(235, 237, 240);
  border-bottom: thin solid rgb(235, 237, 240);
}

/**
   * No content row
   */
.table__no-rows-renderer {
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-align: center;
  vertical-align: middle;
}
