.adminactionrow-0-1 {
  padding-bottom: 25px;
  flex-direction: row;
}

@media (max-width: 768px) {
  .adminactionrow-0-1 {
    flex-direction: column;
  }
}

@media (max-width: 460px) {
  .date-selector {
    flex: 1 1 5%;
  }
}
