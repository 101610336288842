.button {
  border: 0;
  background: 0;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: #2bb7d9;
  cursor: pointer;

  &.big {
    font-size: 20px;
    line-height: 26px;
    color: #203c63;
    span {
      margin-right: 6px;
    }
    i {
      color: #2bb7d9;
      font-size: 14px;
    }
  }

  &.outline {
    border: 1px solid #2bb7d9;
    border-radius: 5px;
    padding: 9px 18px 9px 24px;
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    color: #2bb7d9;
    span {
      margin-right: 30px;
    }
    i {
      color: #2bb7d9;
      font-size: 14px;
    }
  }
}
