/* This stylesheet generated by Transfonter (https://transfonter.org) on April 13, 2018 11:37 AM */

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Bold.eot");
  src: url("Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Bold.woff2") format("woff2"),
    url("Gilroy-Bold.woff") format("woff"),
    url("Gilroy-Bold.ttf") format("truetype"),
    url("Gilroy-Bold.svg#Gilroy-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Medium.eot");
  src: url("Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Medium.woff2") format("woff2"),
    url("Gilroy-Medium.woff") format("woff"),
    url("Gilroy-Medium.ttf") format("truetype"),
    url("Gilroy-Medium.svg#Gilroy-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-SemiBold.eot");
  src: url("Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-SemiBold.woff2") format("woff2"),
    url("Gilroy-SemiBold.woff") format("woff"),
    url("Gilroy-SemiBold.ttf") format("truetype"),
    url("Gilroy-SemiBold.svg#Gilroy-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Regular.eot");
  src: url("Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Regular.woff2") format("woff2"),
    url("Gilroy-Regular.woff") format("woff"),
    url("Gilroy-Regular.ttf") format("truetype"),
    url("Gilroy-Regular.svg#Gilroy-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
