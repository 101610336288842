.list-table-basic {
  .ellipsis {
    display: table;
    table-layout: fixed;
    width: 92%;
    span {
      display: table-cell;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
