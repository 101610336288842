.noteform-0-57 {
  padding: 20px;
}
.noteform-0-58 {
  resize: vertical;
  width: calc(100% - 15px);
}
.noteform-0-59 {
  width: 100%;
  margin-top: 10px;
}
